
import {
  ref, computed, defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { IBreadcrumb } from '@/domains/common';
import { Names } from '@/plugins/vue-router';
import { useI18n } from '@/plugins/vue-i18n';

import { useAssignment } from '@/domains/assignment/composables';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { AtomType } from '@/domains/atom';
import { useQuizSession } from '@/domains/quiz/composables/useQuizSession';
import { useQuizResult } from '@/domains/quiz/composables/useQuizResult';
import { BasePage } from '@/components/ui/BasePage';
import TestResults from '@/components/ui/TestResults';

import { getRunTestAgainButtonTimePart } from '@/helpers/time/blockingRunQuizButton';
import SeparateAtomNewVersionBanner from '@/components/ui/SeparateAtomNewVersionBanner';
import TestDynamicResult from '@/components/ui/TestResults/TestDynamicResult.vue';
import AtomPlayerSidebar from '@/components/ui/AtomSidebar';

export default defineComponent({
  name: 'AssignmentQuizResult',

  components: {
    BasePage,
    TestResults,
    SeparateAtomNewVersionBanner,
    TestDynamicResult,
    AtomPlayerSidebar,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { playerSessionId } = toRefs(props);

    const { t, tc } = useI18n();
    const router = useRouter();

    const isLoading = ref(true);
    const isError = ref(false);

    const {
      isLoading: isAssignmentLoading,
      fetchAssignmentBySession: fetchAssignment,
      assignment,
      isSelfAssignment,
      catalogueAtomVersionId,
      onUnassignHandler,
    } = useAssignment({ playerSessionId, atomType: AtomType.QUIZ });

    const {
      fetchAtom,
      atom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const {
      session: quiz,
      fetchSession,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
      isTestFresh,
      checkNewVersion,
      currentResultDifference,
      showPassAgainButton,
      hasNextAttemptsTimeFlag,
      timeBetweenAttempts,
      hasAttempts,
    } = useQuizSession({ playerSessionId });

    const {
      testPassed,
      isMoreThanOneResult,
      currentPercent,
      testPassingStatusThreshold,
      testPassingStatusImageSrc,
      results,
      hasResults,
      showAnswers,
      answersButtonName,
      fetchResults,
    } = useQuizResult({ playerSessionId, quiz });

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-program-link',
      },
      {
        text: atom.value?.name ?? '',
        'data-test-label': 'program-name-link',
        disabled: true,
      },
    ]);

    const testPassingStatusTitle = computed(() => {
      if (testPassed.value) {
        if (currentPercent.value === 100) {
          return t('AssignmentQuizResult.testPassingStatusTitle.success.perfect');
        }

        return t('AssignmentQuizResult.testPassingStatusTitle.success.default');
      }

      if (hasAttempts.value) {
        return t('AssignmentQuizResult.testPassingStatusTitle.error');
      }

      return t('AssignmentQuizResult.testPassingStatusTitle.failed');
    });

    const testPassingStatusDescription = computed(() => t('AssignmentQuizResult.testPassingStatusDescription', {
      passed: quiz.value.results?.correctAnswers ?? 0,
      all: quiz.value.questionsCount,
      currentPercent: currentPercent.value,
      questions: tc('pluralized.questionsParent', quiz.value.questionsCount),
    }));

    const runTestAgainButtonText = computed(() => {
      const str = getRunTestAgainButtonTimePart(timeBetweenAttempts.value);

      return str.length
        ? t('QuizResultView.runTestAgainButtonText.time', { time: str })
        : t('QuizResultView.runTestAgainButtonText.withoutTime');
    });

    const toQuizIntroHref = computed(() => {
      return router.resolve({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_INTRO,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      }).href;
    });

    const onClickShowAnswersHandler = () => {
      router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_ANSWERS,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    const step = computed(() => {
      const isFailed = !testPassed.value && hasAttempts.value;
      const isWasted = !testPassed.value && !hasAttempts.value;

      return {
        atomType: AtomType.QUIZ,
        name: atom.value?.name,
        isCompleted: isSessionCompleted.value && !isFailed && !isWasted,
        isFailed,
        isWasted,
        id: atom.value?.id ?? '',
      };
    });

    onMounted(async () => {
      isLoading.value = true;
      isError.value = false;

      Promise.all([
        fetchSession(),
        fetchAssignment()
          .then(fetchAtom),
        fetchResults(),
        checkNewVersion(),
      ])
        .catch((e: any) => {
          console.error(e);
          isError.value = true;
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    return {
      isLoading,
      quiz,
      atom,
      assignment,
      isAssignmentLoading,
      isSelfAssignment,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
      breadcrumbs,
      onClickShowAnswersHandler,
      onUnassignHandler,

      testPassed,
      currentPercent,
      testPassingStatusThreshold,
      testPassingStatusImageSrc,
      results,
      hasResults,
      showAnswers,
      answersButtonName,
      testPassingStatusTitle,
      testPassingStatusDescription,

      currentResultDifference,
      isMoreThanOneResult,
      showPassAgainButton,
      hasNextAttemptsTimeFlag,
      runTestAgainButtonText,

      toQuizIntroHref,
      isTestFresh,
      isError,
      hasAttempts,

      step,
    };
  },
});
