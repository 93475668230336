import { computed, ref, Ref } from 'vue';
import { useI18n } from '@/plugins/vue-i18n';
import { useDi } from '@/plugins/di';

import { ITestInfoGetResponseData as IQuizResult, ITestSessionPlayerResult } from '@/services/api/lxp-quiz-test/types';
import QuizResultSuccessImage from '@/assets/img/quiz-result-success.png';
import QuizResultFailImage from '@/assets/img/quiz-result-fail.png';

import * as LxpQuizTestService from '@/services/api/lxp-quiz-test';
import { QuizAnswersDisplayType } from '../types';

export const useQuizResult = ({ quiz, playerSessionId }: { quiz: Ref<IQuizResult>, playerSessionId: Ref<number>}) => {
  const { t } = useI18n();
  const di = useDi();

  const results = ref<ITestSessionPlayerResult[]>([]);

  const testPassed = computed(() => quiz.value.results?.passed ?? false);
  const currentPercent = computed(() => {
    const percent = quiz.value.results ? quiz.value.results.percent * 100 : 0;
    return Math.round(percent);
  });
  const testThreshold = computed(() => quiz.value.results?.testThreshold ?? 0);
  const testThresholdPercent = computed(() => testThreshold.value * 100);
  const testPassingStatusThreshold = computed(() => t('QuizResultView.testPassingStatusThreshold', {
    percent: testThresholdPercent.value,
  }));
  const isMoreThanOneResult = computed(() => results.value?.length > 1);

  const testPassingStatusImageSrc = computed(() => {
    if (testPassed.value) {
      return QuizResultSuccessImage;
    }

    return QuizResultFailImage;
  });

  const hasErrors = computed(() =>
    quiz.value.results?.correctAnswers !== quiz.value.questionsCount);

  const showAnswers = computed(() => {
    const displayType = quiz.value.results?.answersDisplayType || QuizAnswersDisplayType.NONE;

    if (displayType === QuizAnswersDisplayType.ALL) {
      return true;
    }

    if (displayType === QuizAnswersDisplayType.WRONG && hasErrors.value) {
      return true;
    }

    return false;
  });

  const fetchResults = async () => {
    try {
      const { playerResults } = await LxpQuizTestService.testSessionPlayerResults(
        { playerSessionId: playerSessionId.value },
      );
      results.value = playerResults.sort(((a, b) => {
        const dateA = +new Date(a.finishedAt);
        const dateB = +new Date(b.finishedAt);

        return dateB - dateA;
      }));
    } catch (e: any) {
      console.error(e);
      di.notify.error({ content: e.message });
    }
  };

  const hasResults = computed(() => results.value?.length > 0);

  const answersButtonName = computed(() => {
    const displayType = quiz.value.results?.answersDisplayType || QuizAnswersDisplayType.NONE;

    switch (displayType) {
      case QuizAnswersDisplayType.ALL:
        return t('QuizResultView.showAnswersActionText');
      default:
        return t('QuizResultView.showErrorsActionText');
    }
  });

  return {
    hasResults,
    isMoreThanOneResult,
    results,
    testPassed,
    currentPercent,
    testPassingStatusThreshold,
    testPassingStatusImageSrc,
    showAnswers,
    answersButtonName,
    fetchResults,
  };
};
