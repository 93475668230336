
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TestItemGoodResult',
  props: {
    correctAnswersCount: {
      type: Number,
      required: true,
    },
    totalQuestionsCount: {
      type: Number,
      required: true,
    },
    percent: {
      type: Number,
      required: true,
    },
  },
});
