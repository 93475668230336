
import {
  defineComponent, computed, Ref, toRefs,
} from 'vue';
import { useI18n } from '@/plugins/vue-i18n';

export default defineComponent({
  name: 'TestDynamicResult',
  props: {
    difference: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const { difference } = toRefs(props);

    const normalizedDiff = computed(() => +difference.value.toFixed(2));

    const isDynamicPositive: Ref<boolean> = computed(() => normalizedDiff.value > 0);
    const isDynamicNegative: Ref<boolean> = computed(() => normalizedDiff.value < 0);
    const diffObj: Ref<{
      icon: string;
      color: string;
      text: string;
    }> = computed(() => {
      if (isDynamicPositive.value) {
        return {
          icon: '$arrowCircleUp',
          color: 'tt-light-green vibrant',
          text: t('TestDynamicResult.text.positive', { difference: Math.round(normalizedDiff.value * 100) }),
        };
      }

      if (isDynamicNegative.value) {
        return {
          icon: '$arrowCircleDown',
          color: 'tt-light-red',
          text: t('TestDynamicResult.text.negative', { difference: Math.round(Math.abs(normalizedDiff.value * 100)) }),
        };
      }

      return {
        icon: '$star',
        color: 'tt-light-yellow',
        text: t('TestDynamicResult.text.none'),
      };
    });

    return {
      diffObj,
    };
  },
});
