
import { defineComponent, PropType } from 'vue';
import { useDayJs } from '@/plugins/dayjs/composables';

import { DateTimeISO8601, PlayerSessionStatus } from '@/domains/common';

import TestItemBadResult from '@/components/ui/TestResults/TestItemBadResult.vue';
import TestItemGoodResult from '@/components/ui/TestResults/TestItemGoodResult.vue';

interface IResultItem {
  correctAnswersCount: number;
  totalQuestionsCount: number;
  finishedAt: DateTimeISO8601;
  status: PlayerSessionStatus;
  id: number;
  percent: number;
  playerSessionId: number;
  passed: boolean;
}

export default defineComponent({
  name: 'TestResults',
  props: {
    results: {
      type: Array as PropType<IResultItem[]>,
      required: true,
    },
  },
  setup() {
    const dayJs = useDayJs();

    const resultComponent = (passed: IResultItem['passed']) => {
      if (!passed) {
        return TestItemBadResult;
      }

      return TestItemGoodResult;
    };
    const formatDate = (finishedDate: IResultItem['finishedAt']) => {
      const date = dayJs(finishedDate).format('DD MMM YYYY');
      const time = dayJs(finishedDate).format('HH:mm');

      return {
        date,
        time,
      };
    };

    const calcPercent = (value: number) => {
      return Math.round(value * 100);
    };

    return {
      resultComponent,
      formatDate,
      calcPercent,
    };
  },
});
